<template>
  <div id="app">
    <router-view/>
    <van-tabbar :before-change="beforeTabChange" active-color="rgb(220, 0, 2)" fixed route>
      <van-tabbar-item to="/" icon="home-o">809图库</van-tabbar-item>
      <van-tabbar-item icon="gem-o">寻宝</van-tabbar-item>
      <van-tabbar-item icon="shop-o">淘料市场</van-tabbar-item>
      <van-tabbar-item icon="user-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Toast } from 'vant';

export default {
  name: 'App',
  methods: {
    beforeTabChange(index) {
      if (index === 0) {
        return true
      } else if (index === 1) {
        window.location.href = '/site?id=2'
        return false
      } else {
        Toast('开发中')
        return false
      }
    }
  }
}

</script>

<style lang="css" src="./assets/common.css">
</style>
