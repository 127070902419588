import Vue from 'vue'
import VueRouter from 'vue-router'
import lanhu_shouye2 from '../views/lanhu_shouye2/index.vue'

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    component: lanhu_shouye2
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
export default router
